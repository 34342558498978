import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240618-Rate-Sheet.pdf";
const date = "18.06.2024";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Tuesday 18 June 2024 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
      <li>2 Year Fixed &ndash; 30 September 2026</li>
      <li>3 Year Fixed &ndash; 30 September 2027</li>
      <li>5 Year Fixed &ndash; 30 September 2029</li>
      <li>10 Year Fixed &ndash; 30 September 2034</li>
    </ul>
    <p>
      We are increasing our cashback incentives across all of our Energy Efficient Homes (properties with an EPC rating
      of A or B) ranges for First Time Buyer, Home Mover and Remortgage customers and they will range from &pound;750 to
      &pound;2,250 until further notice.
    </p>
    <p>There are no changes to any of our interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 18 June 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
